import React from 'react';

export default function () {
    return <svg xmlns="http://www.w3.org/2000/svg" width="131" height="50" viewBox="0 0 131 50" fill="none" className="arrow">
        <g filter="url(#filter0_d_244_384)">
            <path fillRule="evenodd" clipRule="evenodd" d="M126.403 2.38613L119.951 0L65.2014 20.25L10.4514 0L4 2.38613L61.9711 23.8309H68.4316L126.403 2.38613ZM126.403 22.6361L119.951 20.25L65.2014 40.5L10.4514 20.25L4 22.6361L61.9711 44.0809H68.4316L126.403 22.6361Z" fill="#154299" />
        </g>
        <defs>
            <filter id="filter0_d_244_384" x="0" y="0" width="130.403" height="52.0809" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.66 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_244_384" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_244_384" result="shape" />
            </filter>
        </defs>
    </svg>
}