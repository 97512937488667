import { useState, useEffect } from 'react';

interface textStoreInterface {
    text: string;
}
type ActionFunction = (prevState: any, payload: string) => textStoreInterface;
type DispatchFunction = (actionIdentifier: string, payload: string) => void;

let globalState: textStoreInterface = {text :''};
let listeners: React.Dispatch<React.SetStateAction<textStoreInterface>>[] = [];
let actions: Record<string, ActionFunction> = {};

export const useStore = (): [textStoreInterface, DispatchFunction] => {
    const setState = useState(globalState)[1];

    const dispatch = (actionIdentifier: string, payload: string) => {
        const newState = actions[actionIdentifier](globalState, payload);
        globalState = { ...globalState, ...newState };


        for (const listener of listeners) {
            listener(globalState);
        }
    }

    useEffect(() => {
        listeners.push(setState);

        return () => {
            listeners = listeners.filter(li => li !== setState);
        }
    }, [setState]);

    return [globalState, dispatch];
};

export const initStore = (userActions: Record<string, ActionFunction>, initialState: textStoreInterface) => {
    if (initialState) {
        globalState = { ...globalState, ...initialState };
        actions = { ...actions, ...userActions };
    }
};

export const configureStore = () => {
    const actions = {
        CHANGE_TEXT: (currState: any, newText: string) => {
            return {
                text: newText
            };
        }
    };
    initStore(actions, {
        text: "Hello, welcome to my world!"
    });
};