import React, { useState, useEffect, FC } from 'react';

interface TypewriterProps {
    text: string;
    speed: number;
}

const Typewriter: FC<TypewriterProps> = ({text, speed=50}) => {
    const [displayText, setDisplayText] = useState('');
    useEffect(() => {
        let i = 0;
        setDisplayText('');
        const typingInterval = setInterval(() => {
            let displayString = text.substring(0, i);
            setDisplayText(displayString);
            i++;
            if (i > text.length) {
                clearInterval(typingInterval);
            }
        }, speed);

        return () => {
            clearInterval(typingInterval);
        };
    }, [text]);

    return <div className="typewriter">{displayText} <span className="blinking"> | </span></div>;
}

export default React.memo(Typewriter);