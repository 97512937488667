import React, { useEffect } from 'react';
import Navbar from '../Common/Navbar';
import ProjectCard from './ProjectCard';

import "./Portfolio.css";

const projects = [{
    title: "Robin's Fast File Transfer",
    imgUrl: "/images/projects/FileTransfer.png",
    projectUrl: "https://file-transfer.robinseasky.org/",
    labels: [{
        style: 'green',
        content: 'Available',
    },
    {
        style: 'blue',
        content: 'Service',
    },
    {
        style: 'gray',
        content: 'JavaScript',
    },
    {
        style: 'gray',
        content: 'Java',
    },
    ],
    description: "Are you tired of the hassle of logging into WeChat or Google Drive just to transfer files between your devices? My new, user-friendly file transfer solution makes it easier than ever. Simply drag and drop the file(s) you want to transfer into the interface, and you'll receive a secure 4-digit code which you can immediately use to download files on the target machine! Click here to give it a try!",
},
{
    title: "Bill Splitting Calculator",
    imgUrl: "/images/projects/BillSplitter.png",
    projectUrl: "https://bill.robinseasky.org/",
    labels: [{
        style: 'green',
        content: 'Available',
    },
    {
        style: 'blue',
        content: 'Service',
    },
    {
        style: 'gray',
        content: 'JavaScript',
    },
    ],
    description: "Feel exhausted when splitting the bill after shopping with friends? This tool might help you. Enter the name and price for each idem,  add a person to pay the bill or remove one from the bill by a simple click, and the result is waiting for you. Click here to give it a try!",
},
{
    title: "Triangle Fruit Elimination",
    imgUrl: "/images/projects/FruitElimation.png",
    labels: [{
        style: 'red',
        content: 'Unavailable',
    },
    {
        style: 'blue',
        content: 'Game',
    },
    {
        style: 'gray',
        content: 'Unity',
    },
    ],
    description: "This is a mini-game that was popular 15 years ago. I used to play it a lot when I was young but couldn't find it anymore, so I decided to bring it back. The rules are simple: click on three fruits of the same type, and if they form an isosceles right triangle, they will be eliminated along with all other fruits inside. The more fruits you eliminate, the higher your score. Have fun, and please feel free to leave comments to help improve my game! (Under Construction)",
}]

const Portfolio = () => {

    useEffect(() => {
        const allCards = document.querySelectorAll(".project-card");
        let i = 0;
        const intervalId = setInterval(() => {

            if (i > allCards.length) {
                clearInterval(intervalId);
            }
            else {
                allCards[i]?.classList.remove('hidden');
                allCards[i]?.classList.add('fadein-right');

                // for the remaining cards don't animate
                while (i >= 5 && i < allCards.length) {
                    allCards[i++]?.classList.remove('hidden');
                }
            }
            i++;

        }, 250);

        return () => clearInterval(intervalId);

    }, []);

    return (
        <>
            <Navbar />
            <div className='portfolio'>

                <div className='works'>
                    <div className='title'> Portfolio</div>
                    <div className='card-list'>
                        {
                            projects.map((project, index) => <ProjectCard setting={project} />)
                        }
                    </div>
                    <div className='quote'> The art of programming is the skill of controlling complexity." - Marijn Haverbeke  </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(Portfolio);