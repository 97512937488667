import HomePage from "./Pages/HomePage/HomePage";
import About from "./Pages/StoryPage/About";
import Portfolio from "./Pages/WorkPage/Portfolio";

import "./App.css";
import "./styleguide.css";

import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link,
} from "react-router-dom";


const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <HomePage />
        ),
    },
    {
        path: "about",
        element: (
            <About/>
        ),
    },
    {
        path: "works",
        element: (
            <Portfolio />
        ),
    },
]);
export default function App() {
  return (
    <div className="app">
          <RouterProvider router={router} />
    </div>
  );
}
