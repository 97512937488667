import React, { useEffect, useState } from 'react';

import "./Introduction.css";
import ClickableBlock from "./ClickableBlock";
import Typewriter from "./Typewriter";
import { useStore } from "../../hooks-store/text-store";

import { IconContext } from "react-icons";
import { FaLinkedin } from "react-icons/fa";
import { ImMail } from "react-icons/im";

const Introduction = () => {

    const linkedinProfileUrl = 'https://www.linkedin.com/in/ren-haitian-2b1798262/';
    const emailAddress = 'seaskyren123@gmail.com';
    const welcome = "Hello, welcome to my world!";

    const [aspectRatio, setAspectRatio] = useState(window.innerWidth / window.innerHeight);
    
    const [{ text }, dispatch] = useStore();

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            const newHeight = window.innerHeight;
            setAspectRatio(newWidth / newHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const dispatchMessages = (str: string) => {
        return () =>
            dispatch("CHANGE_TEXT", str);
    }

    return (
        <div className="introduction bouncein-right">
            <div className="name-frame">
                <div className="row">
                    <div className="shadow"> 海 &nbsp; 天 </div>
                    <div className="name" onMouseEnter={dispatchMessages("[Illustration of Name]: In Chinese, Hai(海) is the sea and Tian(天) is the sky. My parents wish me a broad mind like sea and sky.")}> Haitian </div>
                </div>
                <div className="row">
                    <div className="shadow"> 任 </div>
                    <div className="name" onMouseEnter={dispatchMessages("[Family Name]: In Chinese, Ren(任) means responsibility. So am I.")}> Ren </div>
                    <div className="icon-group">
                        <IconContext.Provider value={{ className: "icon linkedin" }}>
                            <a href={linkedinProfileUrl} onMouseEnter={dispatchMessages("Click and connect me on Linkedin!")}  target="_blank"><FaLinkedin /></a>
                        </IconContext.Provider>
                        <IconContext.Provider value={{ className: "icon email" }}>
                            <a href={`mailto:${emailAddress}`} onMouseEnter={dispatchMessages(`Click and send me an email! ${emailAddress} - personal haitinren@gmail.com - career`)}><ImMail /></a>
                        </IconContext.Provider>
                    </div>
                </div>
            </div>
            {
                aspectRatio > 1.2 && <div className="titles" onMouseEnter={dispatchMessages("My best fields are web application, database and game development.")}>
                    <div className="shadow"> A Game Lover</div>
                    <div className="title"> A Software Developer</div>
                </div>
            }
            {
                aspectRatio <= 1.2 && <div className="titles small">
                    <div className="row">
                        <div className="title"> Software </div>
                    </div>
                    <div className="row">
                        <div className="shadow"> Game </div>
                        <div className="title"> Developer </div>
                    </div>
                    <div className="row">
                        <div className="shadow"> Lover </div>
                    </div>
                </div>
            }

            <div className="command-line">
                <div className="prompt">
                    &gt;
                </div>
                <Typewriter text={text} speed={20} />
            </div>

            <div className="seperate-lines">
                <div className="shadow"></div>
                <div className="line"></div>
            </div>
            <div className="links">
                <ClickableBlock title="My Story" link="/about">
                    I was born in China and raised in Shanghai. In my 20s, I came to the United States in pursuit of academic and career opportunities. However, my experience here has not quite matched my expectations.
                </ClickableBlock>
                <ClickableBlock title="My Works" link="/works">
                    I am Software Engineer / Web Developer / Game Designer. Let's dive in and explore the possibilities of my work.
                </ClickableBlock>
            </div>
        </div>
    );
}


export default React.memo(Introduction);