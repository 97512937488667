import React, { useEffect, useRef } from 'react';
import "./HomePage.css";

import Avatar from "./Avatar";
import Background from "./Background";
import Introduction from "./Introduction";
import Logo from "../Common/Logo";
import Arrow from "./Arrow";
import CircularExpandButton from "./CircularExpandButton";

import scrollUpAndMoveToNextPage from "./scrollUpAndMoveToNextPage"
import DelayedLink from '../Common/DelayedLink';

export default function HomePage() {

    useEffect(() => {
        const avatar = document.querySelector('.avatar img');
        const questionmark = document.querySelector('.expand-center');
        if (avatar) {
            //avatar.classList.add("hidden");
            setTimeout(function () {
                avatar.classList.remove("hidden");
                avatar.classList.add("fadein-up")
            }, 800)
        }
        if (questionmark) {
            /*questionmark.classList.add("hidden");*/
            setTimeout(function () {
                questionmark.classList.remove("hidden");
                questionmark.classList.add("zoomin")
            }, 1500)
        }
    }, [])

    return (
        <>
            <Logo />
            <Background />
            <div className="home-page" > 
                <Avatar />
                <CircularExpandButton />
                <Introduction />
                <DelayedLink delay={500} to='/about' onDelayStart={scrollUpAndMoveToNextPage}><Arrow /></DelayedLink>
            </div>
        </>
        
    );
}
