import React, { useRef } from 'react';

import Navbar from "../Common/Navbar";

import "./About.css";
import Arrow from '../HomePage/Arrow';
import DelayedLink from '../Common/DelayedLink';

const About = () => {

    const about = useRef<HTMLDivElement | null>(null);

    const scrollUpAndMoveToNextPage = () => {
        if (about?.current) {
            about.current.classList.add("fadeout-up")
        }
    }

    return (
        <>
            <Navbar />
            <div className='about' ref={about}>

                <div className='card about-me fadein-right'>
                    <div className='title'> About me </div>
                    <div className="photo-frame"><img src="/images/self-story.png" className='photo fadein-right' /></div>
                    
                    <div>
                        <p>
                            Hello! I'm <span className='high-light'>Haitian Ren,</span> a passionate<span className='high-light'>software engineer</span> based in Philadelphia, PA. My dream is to create innovative applications that bring value to users. Currently, I'm actively seeking<span className='high-light'>new career opportunities.</span>If you're interested in connecting or collaborating, feel free to reach out to me at<span className='high-light'>seaskyren123@gmail.com.</span>
                        </p>
                    </div>
                </div>
                <div className='card story fadein-left'>
                    <div className='title'> my Story </div>
                    <div>
                        <p>
                            Born in Zhejiang, China in 1998, I was raised by a family of educators. While many of my families expected me to follow their steps like being a teacher or find a stable job in state-owned enterprise,  I yearned for something different.
                        </p>
                        <p>
                            Rather than conforming to expectations, I found inspiration in video games. I loved the life where I could daunting quests, embark on adventures, and compete with others, which fueled my aversion to an ordinary life and my craving for challenges.
                        </p>
                        <p>
                            As I started school, I excelled academically, but my future remained uncertain until I discovered programming in college. When I'm immersed in programming, it feels like I'm in a gaming world, where challenges abound and the seemingly impossible becomes achievable.
                        </p>
                        <p>
                            Venturing into the world of programming, I realized how much I still had to learn. As graduation approached, I decided to leave my comfort zone, taking a leap to the United States for a master's degree. I dreamed of joining a great company to learn more hands-on programming techniques after graduation. However, life played a joke on me. The pandemic hit, and hiring freezes began, making it difficult for me to find a job.
                        </p>
                        <p>
                            Eventually, I joined a consulting company and began working on contract jobs while I patiently awaited a chance to secure a more permanent position. During this time, I continued to learn and grow, making myself stronger than ever before. Moreover, I found someone with whom I could share my life. With their support, I am optimistic about the future and fully committed to becoming a well-rounded expert in computer science.
                        </p>
                    </div>
                    <br />
                    <div className='quote fadein-right'> "Fortune favors the brave"  -- Terence </div>
                </div>
                <DelayedLink delay={500} to='/works' onDelayStart={scrollUpAndMoveToNextPage}><Arrow /></DelayedLink>
            </div>
        </>
    )
}

export default React.memo(About);