import React, { useState, useEffect, FC } from "react";

import "./CircularExpandButton.css";

import { IconContext } from "react-icons";
import { MdAddCircleOutline } from 'react-icons/md';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import { BiLogoPython, BiLogoUnity, BiLogoJavascript, BiLogoJava, BiLogoSteam } from 'react-icons/bi';
import { BsUnity, BsPlaystation, BsDot, BsExclamationLg } from 'react-icons/bs';
import { TbBrandJavascript, TbBrandBilibili } from 'react-icons/tb'
import { GiPokerHand } from 'react-icons/gi';
import { LiaJava } from 'react-icons/lia';
import { RiTwitterXFill } from 'react-icons/ri';

import { useStore } from "../../hooks-store/text-store";

const CircularExpandButton = () => {
	const [expanded, setExpanded] = useState(false);
	const [first, setFirst] = useState(true);

	const [_, dispatch] = useStore();

	const expandButtonClicked = () => {
		setExpanded(true);
		setFirst(false);
	}

	const contractButtonClicked = () => {
		setExpanded(false);
	}

	const generateCircularMoveKeyFrames = (index : number) => {
		const angle = 135 - 30 * index;
		const neg = -angle;
		return  `@keyframes circular-move-${index} {
			0% {
				transform: rotate(0deg) var(--expand-button-start) rotate(0deg);
			}

			100% {
				transform: rotate(${angle}deg) var(--expand-button-start) rotate(${neg}deg);
			}
		}

		@keyframes circular-contract-${index} {
			0% {
				transform: rotate(${angle}deg) var(--expand-button-start) rotate(${neg}deg);
			}

			100% {
				transform: rotate(0deg) var(--expand-button-start) rotate(0deg);
			}
		}`;
	}

	const generateCircularMoveAnimation = (index: number) => {
		return `circular-move-${index} var(--expand-animation-time) 1 var(--expand-animation-style) forwards`;
	}

	const generateCircularContractAnimation = (index: number) => {
		return `circular-contract-${index} var(--expand-animation-time) 1 var(--expand-animation-style) forwards`;
	}

	useEffect(() => {
		const existingStyleElement = document.querySelector("style#circular-move-keyframes");

		if (existingStyleElement) {
			existingStyleElement.remove();
		}
		const styleElement = document.createElement('style');

		const indexes: Array<number> = [];

		for (let i = 1; i <= 8; i++) {
			indexes.push(i);
		}

		const keyframes = indexes
			.map((index) => generateCircularMoveKeyFrames(index))
			.join('\n');


		styleElement.id = "circular-move-keyframes";
		styleElement.textContent = keyframes;

		document.head.appendChild(styleElement);
	}, [])


	const getIconhoverDisplayMessage = (index:number) => {
		switch (index) {
			case 1: return "[Java]: My favorite programming language.";
			case 2: return "[Unity]: Best tool to create my games.";
			case 3: return "[JavaScript]: Such a headache :( But I still need to use it all the time.";
			case 4: return "[Python]: My first programming language -- a really good one to solve problems.";
			case 5: return "[Bilibili]: A time killer! I could spend a whole day watching videos on it.";
			case 6: return "[Steam]: My favorite PC game platform, as well as my best social platform. My steam id is RobinSeaSky.";
			case 7: return "[Playstation]: I got my PSP in my 10 and now I have a PS5. They are the best game consoles ever.";
			case 8: return "[Pokers]: Let's start gambling!";
			default: return "";
		}
	}

	const dispatchMessages = (str: string) => {
		return () =>
			dispatch("CHANGE_TEXT", str);
	}

	return (
		<div className='expand-center hidden'>
			{
				!expanded && <IconContext.Provider value={{ className: "icon" }}>
					{
						!first && <>
							<div className="item contract java" style={{ animation: generateCircularContractAnimation(1) }}> <LiaJava /> </div>
							<div className="item contract" style={{ animation: generateCircularContractAnimation(2) }}>  <BiLogoUnity /> </div>
							<div className="item contract" style={{ animation: generateCircularContractAnimation(3) }}>  <TbBrandJavascript /> </div>
							<div className="item contract" style={{ animation: generateCircularContractAnimation(4) }}>  <BiLogoPython /> </div>
							<div className="item contract" style={{ animation: generateCircularContractAnimation(5) }}>  <TbBrandBilibili /> </div>
							<div className="item contract" style={{ animation: generateCircularContractAnimation(6) }}>  <BiLogoSteam /> </div>
							<div className="item contract" style={{ animation: generateCircularContractAnimation(7) }}>  <BsPlaystation /> </div>
							<div className="item contract" style={{ animation: generateCircularContractAnimation(8) }}>  <GiPokerHand /> </div>
						</>
					}
					<div className="expand-button" onClick={expandButtonClicked} onMouseEnter={dispatchMessages("What am I thinking every day?") }>
						<BsExclamationLg />
					</div>
				</IconContext.Provider>
			}
			{
				expanded && <>
					<IconContext.Provider value={{ className: "icon" }}>
						<div className="item java" style={{ animation: generateCircularMoveAnimation(1) }} onMouseEnter={dispatchMessages(getIconhoverDisplayMessage(1))}> <LiaJava /> </div>
						<div className="item" style={{ animation: generateCircularMoveAnimation(2) }} onMouseEnter={dispatchMessages(getIconhoverDisplayMessage(2))}>  <BiLogoUnity /> </div>
						<div className="item" style={{ animation: generateCircularMoveAnimation(3) }} onMouseEnter={dispatchMessages(getIconhoverDisplayMessage(3))}>  <TbBrandJavascript /> </div>
						<div className="item" style={{ animation: generateCircularMoveAnimation(4) }} onMouseEnter={dispatchMessages(getIconhoverDisplayMessage(4))}>  <BiLogoPython /> </div>
						<div className="item" style={{ animation: generateCircularMoveAnimation(5) }} onMouseEnter={dispatchMessages(getIconhoverDisplayMessage(5))}>  <TbBrandBilibili /> </div>
						<div className="item" style={{ animation: generateCircularMoveAnimation(6) }} onMouseEnter={dispatchMessages(getIconhoverDisplayMessage(6))}>  <BiLogoSteam /> </div>
						<div className="item" style={{ animation: generateCircularMoveAnimation(7) }} onMouseEnter={dispatchMessages(getIconhoverDisplayMessage(7))}>  <BsPlaystation/> </div>
						<div className="item" style={{ animation: generateCircularMoveAnimation(8) }} onMouseEnter={dispatchMessages(getIconhoverDisplayMessage(8))}>  <GiPokerHand /> </div>

						<div className="contract-button" onClick={contractButtonClicked}>
							<BsDot />
						</div>
					</IconContext.Provider>

				</>
			}
		</div>
	)
}

export default React.memo(CircularExpandButton);