import React from 'react';

import Logo from "./Logo";

import "./Navbar.css";
import { Link, useLocation } from 'react-router-dom';

import { useStore } from "../../hooks-store/text-store";

const Navbar = () => {

    const location = useLocation();
    const [_, dispatch] = useStore();

    const dispatchMessages = (str: string) => {
        return () =>
            dispatch("CHANGE_TEXT", str);
    }

    return (
        <div className="navbar">
            <Logo />
            <div className="menu">
                <Link to="/" className="link" onClick={dispatchMessages('Hello, welcome to my world!') }>
                    HOME
                </Link>
                <Link to="/about" className={`link ${location?.pathname=='/about' ? 'disable' : ''}`} onClick={()=>window.scrollTo(0, 0)}>
                    ABOUT
                </Link>
                <Link to="/works" className={`link ${location?.pathname=='/works' ? 'disable' : ''}`} onClick={()=>window.scrollTo(0, 0)}>
                    WORK
                </Link>
            </div>
        </div>
    );
}

export default React.memo(Navbar);