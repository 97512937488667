import React, { ReactNode, FC} from 'react';

import scrollUpAndMoveToNextPage from "./scrollUpAndMoveToNextPage"
import { Link } from 'react-router-dom';
import DelayedLink from '../Common/DelayedLink';

import "./ClickableBlock.css";

interface ClickableBlockProps {
    title: string;
    link: string;
    children: ReactNode;
}

const ClickableBlock: FC<ClickableBlockProps> = ({ title, link, children }) => {

    return (
        <DelayedLink delay={500}  to={link} className="clickable-block" onDelayStart={scrollUpAndMoveToNextPage }>
            <div className="title"> {title} </div>
            <div className="body clamp"> {children} </div>
            <div className="body viewmore"> [view more] </div>
        </DelayedLink>
    )
}

export default React.memo(ClickableBlock);