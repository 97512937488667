const scrollUpAndMoveToNextPage = () => {
    const homepage = document.querySelector('.home-page');
    if (homepage) {
        homepage.classList.add('fadeout-up');
    }

    const eclipses = document.querySelectorAll('.eclipse:not(.large)');
    for (let i = 0; i < eclipses.length; i++) {
        eclipses[i].classList.add('eclipse-disappear');
    }

    const eclipseLarge = document.querySelector('.eclipse.large');
    if (eclipseLarge) {
        eclipseLarge.classList.add('eclipse-to-bar');
    }
}

export default scrollUpAndMoveToNextPage;