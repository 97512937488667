import React from "react";
import "./Avatar.css";
import { useStore } from "../../hooks-store/text-store";

export default function Avatar() {

    const [_, dispatch] = useStore();

    const dispatchMessages = (str: string) => {
        return () =>
            dispatch("CHANGE_TEXT", str);
    }

    return (
        <div className="avatar" >
            <img className='hidden' onMouseEnter={dispatchMessages("(〃▽〃)")}  src="/images/avatar.png" alt="My Avatar" />
        </div>
    );
}
