import React, { useEffect, FC, ReactNode } from "react";

import { Link, useNavigate, useLocation } from "react-router-dom";
interface DelaydLinkProps {
    delay: number,
    onDelayStart: () => void,
    to: string,
    className?: string,
    children: ReactNode,
}

const DelayedLink: FC<DelaydLinkProps> = ({ delay, onDelayStart, to, className, children }) => {
    let timeout:string|null = null;
    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [timeout]);

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        // if trying to navigate to current page stop everything
        if (location?.pathname === to) return;

        onDelayStart();
        if (event.defaultPrevented) {
            return;
        }

        event.preventDefault();

        setTimeout(() => {
            window.scrollTo(0, 0);
            navigate(to);
        }, delay);
    };

    return <Link to={to} onClick={handleClick} className={className}> {children} </Link>;
};

export default DelayedLink;