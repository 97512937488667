import React, { FC } from 'react';

import './ProjectCard.css';

interface Label {
    style: string,
    content: string,
}

interface CardSetting {
    title: string,
    imgUrl: string,
    labels: Array<Label>
    description: string,
    projectUrl?: string,
}

interface ProjectCardProps {
    setting: CardSetting,
}

const ProjectCard: FC<ProjectCardProps> = ({ setting: { title, imgUrl, labels, description, projectUrl } }) => {

    const getContent = () => (
        <div className='project-card hidden'>
            <img className='project-img' src={imgUrl} />
            <div className='project-right-layout'>
                <div className='project-title'> {title} </div>
                <div className='project-labels'>
                    {labels.map(({ style, content }) => <div className={`project-label ${style}`} > {content} </div>)}
                </div>
                <div className='project-description'> {description} </div>
            </div>
        </div>
    )

    if (projectUrl) {
        return (
            <a className='project-card-link' href={projectUrl} target='_blank' rel='noopener noreferrer'>
                {getContent()}
            </a>
        )
    }
    else {
        return getContent();
    }
}

export default React.memo(ProjectCard);